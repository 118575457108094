/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		:768.99px;
$breakpoint_tb		:1100.99px;
$breakpoint_pc		:$breakpoint_sp + .1;


/* Color */
$c_base : #c41f63;//サイトカラー
$c_text : #222222; // テキストに使うフォントカラー
$c_href : #c41f63; // テキスト内で使うリンクカラー
$c_bo : #dbdbdb; //ボーダー
$c_blue : #0061c4; //青

$c_baseDeep : #ffcd0d;
$c_gray: #93989e;


@mixin maxSP {
    padding: 0 14px;
    margin: 0 auto;
}

@mixin maxPC {
 max-width: 1220px;
 padding: 0 40px;
 margin: 0 auto;
}

@mixin fontUsu {
    font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
 //font-family: "Zen Kaku Gothic New", sans-serif;
}
@mixin fontEn {
 font-family: "Baskervville", sans-serif;
}

@mixin fontYu {
    font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}
   
