body {
  @if $type =="" {
    font-weight: 500;
    color: $c_text;
    @include fontUsu;
    overflow-x: hidden;
    position: relative;
  }

  @if $type ==sp {
    font-size: 15px;
    line-height: 1.55;
    -webkit-overflow-scrolling: touch;

  }

  @if $type ==header_sp {

    &.isOpen {
      #globalHeader.isView {
        transform: translateY(0) !important;
      }
    }
  }

  @if $type ==pc {
    font-size: 18px;
    line-height: 1.77;
  }

  @if $type ==header_pc {
    padding-top: 109px;
  }
}

a {
  @if $type =="" {
    color: #000000;
    text-decoration: none;
  }
}

.eng {
  font-family: Petrona, serif;
}

ul,
ol {
  @if $type =="" {
    list-style-type: none;
  }
}

img {
  @if $type =="" {
    max-width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
}

* {
  @if $type =="" {
    box-sizing: border-box;
  }
}

.tel {
  @if $type ==sp {
    color: $c_base;
    text-decoration: underline;
  }

  @if $type ==pc {
    color: inherit !important;
    text-decoration: none !important;
    pointer-events: none;
  }
}

sup,
sub {
  @if $type =="" {
    font-size: .6em;
  }
}

.dInlineBlock {
  @if $type =="" {
    display: inline-block !important;
  }
}

.fontEn {
  @if $type =="" {
    @include fontEn;
  }
}

/* ----------------- header ------------------ */
header {
  @if $type =="" {
    font-weight: 400;
    letter-spacing: 0;
  }
  @if $type ==header_sp {
    font-family: "Noto Sans JP", "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
    background-color: #c41f63;
    width: 100%;
    height: 65px;
    position: relative;

    &.smallPC  {
      width: 100%;
      height: 65px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;

      
      #headerInner {
        width: 100%;

        #haderMenu {
          display: none;
        }

        .forSmallScreen {
          display: block;
        }

        #logo {
          display: block;
          width: 83px;
          height: auto;

          img {
            width: 100%;
            height: auto; 
          }
        }

        .spHeadTxt {
          left: 90px;
        }

        #spMenuBtn {
          width: 83px;
          height: 83px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 100; 

          img {
            width: 100%;
            height: auto;
          }
        }

        #globalMenu {
          width: 100%;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.7);
          position: fixed;
          top: 0;
          left: 100%;
          z-index: 900;
          transition: all .3s;

          &.opened {
            left: 0%;
          }

          #spMenuClose {
            width: 83px;
            height: 83px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 6;

            img {
              width: 100%;
              height: auto; 
            }
          }

          ul {
            width: 210px;
            min-height: 100vh;
            background-image: url(../images/common/sp-menu-bg.png);
            background-repeat: repeat;
            position: absolute;
            top: 0;
            right: -210px;
            z-index: 5;
            list-style: none;
            transition: all .3s;
            transition-delay: .3s;
            display: block;

            &.opened {
              right: 0px;
            }

            li {
              display: block;
              height: 60px;
              padding: 0;

              &:after {
                display: none;
              }

              a {
                font-size: 22px;
                color: #fff;
                text-decoration: none;
                display: block;
                width: 100%;
                padding: 0 0 0 30px;
                line-height: 1.5;
                position: relative;
                border-radius: 0;

                &:hover {
                  color: #c41f63;
                }

                &:before {
                  content: "";
                  display: block;
                  width: 10px;
                  height: 10px;
                  border-top: 2px solid #c41f63;
                  border-right: 2px solid #c41f63;
                  transform: rotate(45deg);
                  position: absolute;
                  top: 10px;
                  left: 8px;
                }

                &.spBnr {
                  width: 200px;
                  height: 50px;
                  border-radius: 0;

                  &.seminar {
                    border: 1px solid #fff;
                    margin-left: 5px;
                    margin-bottom: 15px;
                    font-size: 20px;
                    padding: 5px 0 0;
                    text-align: center;

                    &:before {
                      display: none;
                    }

                    .jp {
                      margin-top: -7px;
                    }
                  }

                  &.contactBtn {
                    background-color: #c41f63;
                    color: #fff;
                    margin-left: 5px;
                    line-height: 50px;
                    font-size: 14px;
                    padding: 0;
                    text-align: center;

                    &:before {
                      display: none;
                    }

                    &:hover {
                      opacity: .7;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  @if $type ==header_pc {
    font-family: "Petrona", serif;
    background-color: #c41f63;
    height: 109px;
    line-height: 1.5;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;


  }
  @if $type ==tb {
    &.smallPC {

      #headerInner {
        width: 100%;

        #haderMenu {
          display: none;
        }

        .forSmallScreen {
          display: block;
        }

        #logo {
          display: block;
          width: 83px;
          height: auto;

          img {
            width: 100%;
            height: auto; 
          }
        }

        #spMenuBtn {
          width: 83px;
          height: 83px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 100; 

          img {
            width: 100%;
            height: auto;
          }
        }

        #globalMenu {
          width: 100%;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.7);
          position: fixed;
          top: 0;
          left: 100%;
          z-index: 900;
          transition: all .3s;

          &.opened {
            left: 0%;
          }

          #spMenuClose {
            width: 83px;
            height: 83px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 6;

            img {
              width: 100%;
              height: auto; 
            }
          }

          ul {
            width: 210px;
            min-height: 100vh;
            background-image: url(../images/common/sp-menu-bg.png);
            background-repeat: repeat;
            position: absolute;
            top: 0;
            right: -210px;
            z-index: 5;
            list-style: none;
            transition: all .3s;
            transition-delay: .3s;
            display: block;

            &.opened {
              right: 0px;
            }

            li {
              display: block;
              height: 60px;
              padding: 0;

              &:after {
                display: none;
              }

              a {
                font-size: 22px;
                color: #fff;
                text-decoration: none;
                display: block;
                width: 100%;
                padding: 0 0 0 30px;
                position: relative;
                border-radius: 0;

                &:hover {
                  color: #c41f63;
                }

                &:before {
                  content: "";
                  display: block;
                  width: 10px;
                  height: 10px;
                  border-top: 2px solid #c41f63;
                  border-right: 2px solid #c41f63;
                  transform: rotate(45deg);
                  position: absolute;
                  top: 10px;
                  left: 8px;
                }

                &.spBnr {
                  width: 200px;
                  height: 50px;
                  border-radius: 0;

                  &.seminar {
                    border: 1px solid #fff;
                    margin-left: 5px;
                    margin-bottom: 15px;
                    font-size: 20px;
                    padding: 5px 0 0;
                    text-align: center;

                    &:before {
                      display: none;
                    }

                    .jp {
                      margin-top: -7px;
                    }
                  }

                  &.contactBtn {
                    background-color: #c41f63;
                    color: #fff;
                    margin-left: 5px;
                    line-height: 50px;
                    font-size: 14px;
                    padding: 0;
                    text-align: center;

                    &:before {
                      display: none;
                    }

                    &:hover {
                      opacity: .7;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }


  .forSmallScreen {
    @if $type ==header_sp {}

    @if $type ==header_pc {
      display: none;
    }
  }

  
  .spHeadTxt {
    @if $type =="" {
    }
    @if $type ==header_sp {
      display: block;
      color: #fff;
      font-size: 11px;
      position: absolute;
      left: 80px;
      top: 19px;
      line-height: 1.3;
      text-decoration-skip: none !important;
    }
    @if $type ==header_pc {
      display: none;
    }
    @if $type ==header_tb {
      font-size: 12px;
      left: 90px;
      top: 18px;

    }
  }

  #logo {
    @if $type ==header_sp {
      padding: 17px 0 0 13px;
      display: inline-block;
      line-height: 1;
    }

    @if $type ==header_pc {
      padding: 30px 45px 0 35px;
      float: left;
      line-height: 1;
    }

    @if $type ==header_tb {
      padding-top: 15px;
      padding-left: 15px;
    }

    img {
      @if $type ==header_sp {
        display: block;
        width: 57px;
        height: auto;
      }

      @if $type ==header_pc {
        width: 84px;
        height: auto;
      }
    }
  }

  #spMenuBtn {
    @if $type ==header_sp {
      width: 83px;
      height: 83px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
    }

    @if $type ==header_pc {}

    img {
      @if $type ==header_sp {
        width: 100%;
        height: auto;
      }

      @if $type ==header_pc {}
    }
  }

  #globalMenu {
    @if $type ==header_sp {
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.7);
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 900;
      transition: all .3s;
    }

    @if $type ==header_pc {
      float: left;
    }

    &.opened {
      @if $type ==header_sp {
        left: 0%;
      }

      @if $type ==header_pc {}
    }

    #spMenuClose {
      @if $type ==header_sp {
        width: 83px;
        height: 83px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 6;
      }

      @if $type ==header_pc {}

      img {
        @if $type ==header_sp {
          width: 100%;
          height: auto;
        }

        @if $type ==header_pc {}
      }
    }

    ul {
      @if $type ==header_sp {
        width: 210px;
        min-height: 100vh;
        background-image: url(../images/common/sp-menu-bg.png);
        background-repeat: repeat;
        position: absolute;
        top: 0;
        right: -210px;
        z-index: 5;
        list-style: none;
        transition: all .3s;
        transition-delay: .3s;
      }

      @if $type ==header_pc {
        list-style: none;
        display: table;
        height: 109px;
      }

      &.opened {
        @if $type ==header_sp {
          right: 0px;
        }

        @if $type ==header_pc {}
      }

      li {
        @if $type ==header_sp {}

        @if $type ==header_pc {
          display: table-cell;
          height: 109px;
          vertical-align: middle;
          position: relative;
        }

        &.endOfPC {
          @if $type ==header_sp {
            margin-bottom: 10px;
          }

          @if $type ==header_pc {}
          @if $type ==header_tb {
            margin-bottom: 0;
          }
        }

        &.forSmallScreen {
          @if $type =="" {}

          @if $type ==header_sp {}

          @if $type ==header_pc {
            display: none;
          }
        }

        &:not(.endOfPC) {
          @if $type =="" {}

          @if $type ==header_sp {}

          @if $type ==header_pc {
            padding-right: 13px;
          }

          &:after {
            @if $type =="" {}

            @if $type ==header_sp {}

            @if $type ==header_pc {
              content: "";
              display: inline-block;
              margin-left: 13px;
              width: 1px;
              height: 14px;
              background-color: #fff;
              vertical-align: middle;
            }
          }
        }

        a {
          @if $type ==header_sp {
            font-size: 22px;
            color: #fff;
            text-decoration: none;
            display: block;
            width: 100%;
            padding-top: 0px;
            line-height: 1.3;
            padding-top: 12px;
            padding-left: 25px;
            position: relative;
          }

          @if $type ==header_pc {
            color: #fff;
            font-size: 14px;
            text-decoration: none;
            display: inline-block;
            padding: 10px;
            border-radius: 10px 0 10px 0;

            &:hover {
              background-color: rgba(0, 0, 0, 0.3);
              text-shadow: 1px 1px 3px #000;
              transition: all .3s;
            }
          }

          &:before {
            @if $type ==header_sp {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              border-top: 2px solid #c41f63;
              border-right: 2px solid #c41f63;
              transform: rotate(45deg);
              position: absolute;
              top: 20px;
              left: 8px;
            }

            @if $type ==header_pc {}
          }

          .jp {
            @if $type ==header_sp {
              display: block;
              font-size: 9px;
              color: #ccc;
              font-family: "Noto Sans JP", "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
              margin-top: -3px;
            }

            @if $type ==header_pc {
              display: none;
            }
            @if $type ==header_tb {
              font-size: 10px;
            }
          }

          &.spBnr {
            @if $type ==header_sp {
              width: 200px;
              height: 50px;
            }

            @if $type ==header_pc {}

            &.seminar {
              @if $type ==header_sp {
                border: 1px solid #fff;
                margin-left: 5px;
                margin-bottom: 15px;
                font-size: 16px;
                padding-top: 10px;
              }

              @if $type ==header_pc {}

              &:before {
                @if $type ==header_sp {
                  border-color: #fff;
                  top: 18px;
                }

                @if $type ==header_pc {}
              }
            }

            &.contactBtn {
              @if $type ==header_sp {
                background-color: #c41f63;
                color: #fff;
                margin-left: 5px;
                font-size: 13px;
                padding-top: 16px;
              }

              @if $type ==header_pc {}

              &:before {
                @if $type ==header_sp {
                  border-color: #fff;
                  top: 18px;
                }

                @if $type ==header_pc {}
              }
            }
          }
        }
      }
    }
  }

  #headerInner {
    @if $type =="" {}

    @if $type ==header_sp {}

    @if $type ==header_pc {
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }

    #haderMenu {
      @if $type =="" {}

      @if $type ==header_sp {
        display: none;
      }

      @if $type ==header_pc {
        //display: table;
        //float: right;
        display: flex;
        justify-content: flex-end;
        list-style: none;
        margin-right: 35px;
      }

      li {
        @if $type =="" {}

        @if $type ==header_sp {}

        @if $type ==header_pc {
          display: table-cell;
          padding-top: 36px;
          padding-left: 10px;
        }

        
        &.contactCell {
          @if $type =="" {
            font-family: "Noto Sans JP", "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
          }

          @if $type ==header_sp {}

          @if $type ==header_pc {
            position: relative;

            width: 205px;
            background-image: url(../images/common/header-tel.png);
            background-repeat: no-repeat;
            background-position: 10px 27px;
            padding-top: 59px;
            padding-left: 10px;
          }

          .contactBtn {
            @if $type =="" {
            }
            @if $type ==header_sp {
            }
            @if $type ==header_pc {
              display: block;
              background-color: #fff;
              position: absolute;
              color: #c41f63;
              text-decoration: none;
              width: 195px;
              height: 23px;
              padding-left: 30px;
              font-size: 14px;
              line-height: 23px;
              transition: all .3s;
            }

            &:after {
              @if $type =="" {
              }
              @if $type ==header_sp {
              }
              @if $type ==header_pc {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-top: 2px solid #c41f63;
                border-right: 2px solid #c41f63;
                position: absolute;
                top: 8px;
                left: 10px;
                transform: rotate(45deg);
              }
            }

            &:hover {
              @if $type =="" {
              }
              @if $type ==header_sp {
              }
              @if $type ==header_pc {
                opacity: .8;
                border-radius: 5px;
              }
            }
          }
        }

        a {
          @if $type =="" {}

          @if $type ==header_sp {}

          @if $type ==header_pc {
            display: block;
          }

          &.seminar {
            @if $type =="" {}

            @if $type ==header_sp {}

            @if $type ==header_pc {
              width: 186px;
              height: 55px;
              border: 1px solid #fff;
              color: #fff;
              font-size: 19px;
              padding-left: 25px;
              padding-top: 3px;
              position: relative;
              text-decoration: none;
              transition: all .3s;
            }

            &:after {
              @if $type =="" {}

              @if $type ==header_sp {}

              @if $type ==header_pc {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-top: 2px solid #fff;
                border-right: 2px solid #fff;
                position: absolute;
                top: 21px;
                left: 8px;
                transform: rotate(45deg);
                transition: all .3s;
              }
            }

            span {
              @if $type =="" {}

              @if $type ==header_sp {}

              @if $type ==header_pc {
                font-family: "Noto Sans JP", "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
                font-size: 9px;
                display: block;
                transition: all .3s;
              }
            }

            &:hover {
              @if $type =="" {}

              @if $type ==header_sp {}

              @if $type ==header_pc {
                background-color: #fff;
                color: #c41f63;

                &:after {
                  border-color: #c41f63;
                }

                span {
                  color: #c41f63;
                }
              }
            }

            &.wide {
              @if $type =="" {}

              @if $type ==header_sp {}

              @if $type ==header_pc {
                width: 205px;
              }
            }
          }
        }

      }
    }
  }
}

  // #globalHeader {
  // 	@if $type =="" {
  // 		width: 100%;
  //     background: $c_base;
  // 		position: fixed;
  // 		top: 0;
  // 		left: 0;
  // 		z-index: 101;
  // 		transition:.4s transform;
  // 	}

  // 	@if $type ==header_sp {
  // 		z-index: 101;

  // 		&.isView {
  // 			transform: translateY(-100px);
  // 		}

  // 		&:not(.isView) {
  // 			transform: translateY(0);
  // 		}

  // 	}

  // 	@if $type ==header_pc {

  // 		&.isView {
  // 			transform: translateY(-100px);
  // 		}

  // 		&:not(.isView) {
  // 			transform: translateY(0);
  // 		}

  // 	}

  //   .ghInner {
  //     @if $type =="" {
  //     }
  //     @if $type ==header_sp {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       min-height: 65px;
  //     }
  //     @if $type ==header_pc {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;
  //       min-height: 90px;
  //       padding-inline: 35px;
  //     }
  //   }

  //   .ghLogo {
  //     @if $type =="" {
  //     }
  //     @if $type ==header_sp {
  //       margin-left: 15px;
  //     }
  //     @if $type ==header_pc {
  //       width: 64px;

  //       span {
  //         display: none !important;
  //       }
  //     }

  //     a {
  //       @if $type =="" {
  //         line-height: 1;
  //       }
  //       @if $type ==header_sp {
  //         display: flex;
  //         align-items: center;

  //           span {
  //             color: #fff;
  //             font-size: 12px;
  //             line-height: 1.3;
  //             font-weight: 400;
  //           }
  //       }
  //       @if $type ==header_pc {
  //         display: block;
  //       }

  //       img {
  //         @if $type =="" {
  //           display: block;
  //           line-height: 1;
  //         }
  //         @if $type ==header_sp {
  //           width: 65px;
  //           margin-right: 10px;
  //         }
  //         @if $type ==header_pc {
  //         }
  //       }


  //     }
  //   }

  //   #ghMenuBtn {
  //     @if $type =="" {}

  //     @if $type ==header_sp {
  //       appearance: none;
  //       border: none;
  //       background: transparent;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       color: transparent;
  //       font-size: 0;
  //       text-indent: -9999px;
  //       width: 50px;
  //       height: 47px;
  //       transition: all 0.4s;
  //       user-select: none;
  //       position: absolute;
  //       top: 0;
  //       right: 0;
  //       z-index: 110;
  //       cursor: pointer;

  //       &:before {
  //         content: "";
  //         width: 0;
  //         height: 0;
  //         border-style: solid;
  //         border-color: transparent #ffffff transparent transparent;
  //         border-width: 0px 83px 83px 0px;
  //         position: absolute;
  //         top: 0;
  //         right: 0;
  //         transition: .2s border-color;
  //       }
  //     }

  //     @if $type ==header_pc {
  //       display: none !important;
  //     }

  //     @if $type ==header_sp {
  //       >span {
  //         background: #000;
  //         position: absolute;
  //         top: 0;
  //         bottom: 0;
  //         right: 0;
  //         left: 0;
  //         margin: auto;
  //         transition: .4s transform, .4s opacity, .4s background;
  //         z-index: 1;
  //       }

  //       >span {
  //         width: 24px;
  //         height: 2px;

  //         &:nth-child(1) {
  //           transform: translateY(-7px);
  //         }

  //         &:nth-child(2) {
  //           transform: translateY(7px);
  //         }

  //       }

  //       &.isOpen {

  //         &:before {
  //           border-color: transparent $c_base transparent transparent;
  //         }

  //         >span {
  //           background: #fff;

  //           &:nth-child(1) {
  //             transform: rotate(45deg);
  //           }

  //           &:nth-child(2) {
  //             transform: rotate(-45deg);
  //           }

  //           &:nth-child(3) {
  //             opacity: 0;
  //           }
  //         }
  //       }
  //     }

  //   }

  //   .ghMenu {
  //     @if $type =="" {
  //     }
  //     @if $type ==header_sp {
  //       display: none;

  //       &:before {
  //         content: "";
  //         display: block;
  //         width: 100%;
  //         height: calc(100vh + 130px);
  //         background: rgba(0, 0, 0, 0.7);
  //         position: fixed;
  //         top: 0;
  //         left: 0;
  //         transition: .2s opacity;
  //       }
  //     }
  //     @if $type ==header_pc {
  //       display: block !important;
  //     }

  //     .ghNav {
  //       @if $type =="" {
  //       }
  //       @if $type ==header_sp {
  //         width: 240px;
  //         height: 100vh;
  //         padding: 17px 15px 40px;
  //         background: #fff;
  //         margin: auto;
  //         z-index: 10;
  //         position: fixed;
  //         top: 0;
  //         right: 0;
  //       }
  //       @if $type ==header_pc {
  //         display: flex;
  //         align-items: center;
  //       }

  //       ul {
  //         @if $type =="" {
  //         }
  //         @if $type ==header_sp {
  //         }
  //         @if $type ==header_pc {
  //           display: flex;
  //           margin-right: unquote("min(22px, 1.14vw)");
  //         }

  //         li {
  //           @if $type =="" {
  //           }
  //           @if $type ==header_sp {
  //             position: relative;
  //           }
  //           @if $type ==header_pc {
  //           }

  //           + li {
  //             @if $type =="" {
  //             }
  //             @if $type ==header_sp {
  //               margin-top: 20px;
  //             }
  //             @if $type ==header_pc {
  //             }
  //           }

  //           a {
  //             @if $type =="" {
  //             }
  //             @if $type ==header_sp {
  //               text-decoration: none;
  //               width: 100%;
  //               padding-top: 0px;
  //               line-height: 1.3;
  //               padding-left: 25px;
  //               position: relative;

  //               display: block;
  //               font-size: 9px;
  //               margin-top: -3px;

  //               &:before {
  //                 content: attr(data-text);
  //                 @include fontEn;
  //                 display: block;
  //                 font-size: 22px;
  //               }

  //               &:after {
  //                 content: "";
  //                 display: block;
  //                 width: 10px;
  //                 height: 10px;
  //                 border-top: 2px solid #c41f63;
  //                 border-right: 2px solid #c41f63;
  //                 transform: rotate(45deg);
  //                 position: absolute;
  //                 top: 8px;
  //                 left: 8px;
  //               }
  //             }
  //             @if $type ==header_pc {
  //               padding-block: unquote("min(13px, 0.67vw)");
  //               padding-inline: unquote("min(13px, 0.67vw)");
  //               color: #fff;
  //               font-size: unquote("min(17px, 1.3vw)");
  //               border-radius: 9999px;
  //               transition: .2s background;

  //               &:hover,
  //               &:focus-visible {
  //                 background: rgba(255,255,255,.3);
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }

  //     .ghContact {
  //       @if $type =="" {
  //       }
  //       @if $type ==header_sp {
  //         margin-top: 20px;
  //       }
  //       @if $type ==header_pc {
  //       }

  //       a {
  //         @if $type =="" {
  //           display: flex;
  //           align-items: center;
  //           justify-content: center;
  //           position: relative;
  //         }
  //         @if $type ==header_sp {
  //           padding: 11px 15px;
  //           color: #fff;
  //           font-weight: 500;
  //           border-radius: 9999px;
  //           background: rgb(248,101,125);
  //           background: linear-gradient(222deg, rgba(248,101,125,1) 0%, rgba(196,31,99,1) 100%);    
  //           transition: .2s filter;
  //         }
  //         @if $type ==header_pc {
  //           padding: 11px 15px;
  //           color: $c_base;
  //           font-size: unquote("min(16px, 1.25vw)");
  //           font-weight: 500;
  //           border-radius: 9999px;
  //           background: #fff;
  //           transition: .2s filter;

  //           &:hover,
  //           &:focus-visible {
  //             >span {
  //               span {
  //                transform: translateY(1.77em);
  //                filter: inherit;
  //               }
  //              }
  //           }
  //         }

  //         &:before {
  //           @if $type =="" {
  //             content: "";
  //             display: block;
  //           }
  //           @if $type ==header_sp {
  //             width: 20px;
  //             height: 20px;
  //             margin-right: 12px;
  //             background: url(../images2024/common/icon_mail_white.svg) no-repeat center;
  //             background-size: contain;
  //           }
  //           @if $type ==header_pc {
  //             width: 20px;
  //             height: 20px;
  //             margin-right: 8px;
  //             background: url(../images2024/common/icon_mail.svg) no-repeat center;
  //             background-size: contain;
  //           }
  //         }

  //         >span{
  //           @if $type =="" {
  //           }
  //           @if $type ==sp {
  //           }
  //           @if $type ==pc {
  //             display: flex;
  //             flex-direction: column;
  //             height: 1.77em;
  //             overflow: hidden;
  //           }

  //           span {
  //             @if $type =="" {
  //               display: block;
  //             }
  //             @if $type ==sp {
  //             }
  //             @if $type ==pc {
  //               margin-top: -1.77em;
  //               transition: .2s transform;
  //             }

  //             &:before {
  //               @if $type =="" {
  //               }
  //               @if $type ==sp {
  //               }
  //               @if $type ==pc {
  //                 content: attr(data-text);
  //                 display: block;
  //               }
  //             }
  //           }
  //         }
  //         }
  //     }
  //   }

  // }



  main {
    @if $type =="" {
      display: block;
    }

    @if $type ==header_sp {
      //padding-top: 65px;
    }

    @if $type ==header_pc {
      //padding-top: 90px;
    }
  }

  .section {
    @if $type =="" {}

    @if $type ==sp {}

    @if $type ==pc {}

    .inner {
      @if $type =="" {}

      @if $type ==sp {
        @include maxSP;
      }

      @if $type ==pc {
        @include maxPC;
      }

    }
  }

  *+.btnWrap {
    @if $type =="" {}

    @if $type ==sp {
      margin-top: 22px;
    }

    @if $type ==pc {
      margin-top: 65px;
    }
  }

  .btnWrap {
    @if $type =="" {
      text-align: center;
    }

    @if $type ==sp {}

    @if $type ==pc {}

    a {
      @if $type =="" {
        display: inline-block;
        color: #fff;
        border-radius: 9999px;
        text-align: center;
        background: rgb(248, 101, 125);
        background: linear-gradient(222deg, rgba(248, 101, 125, 1) 0%, rgba(196, 31, 99, 1) 100%);
        position: relative;
      }

      @if $type ==sp {
        min-width: 230px;
        padding: 14px 30px;
        font-size: 15px;
        letter-spacing: 0.1em;
      }

      @if $type ==pc {
        min-width: 260px;
        padding: 9px 30px;
        font-size: 19px;
        letter-spacing: 0.1em;
        transition: .2s filter;

        &:focus-visible,
        &:hover {
          >span {
            span {
              transform: translateY(1.67em);
            }
          }
        }
      }

      &:before {
        @if $type =="" {
          content: "";
          display: block;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;
          transform: rotate(-45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          margin-block: auto;
        }

        @if $type ==sp {
          width: 8px;
          height: 8px;
          right: 20px;
        }

        @if $type ==pc {
          width: 7px;
          height: 7px;
          right: 20px;
        }
      }

      >span {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
          display: flex;
          flex-direction: column;
          height: 1.67em;
          overflow: hidden;
        }

        span {
          @if $type =="" {
            display: block;
          }

          @if $type ==sp {}

          @if $type ==pc {
            margin-top: -1.67em;
            transition: .2s transform;
          }

          &:before {
            @if $type =="" {}

            @if $type ==sp {}

            @if $type ==pc {
              content: attr(data-text);
              display: block;
            }
          }
        }
      }
    }
  }



  #sectionContact {
    @if $type =="" {
      color: #fff;
      background: rgb(254, 113, 114);
      background: linear-gradient(100deg, rgba(254, 113, 114, 1) 0%, rgba(196, 32, 99, 1) 100%);

    }

    @if $type ==sp {
      padding-block: 47px 62px;
    }

    @if $type ==pc {
      padding-block: 115px 114px;
    }

    .inner {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {
        display: flex;
        align-items: center;
        @include maxPC;
      }

      @if $type ==tb {
        flex-direction: column;
      }

      .contactHeader {
        @if $type =="" {}

        @if $type ==sp {}

        @if $type ==pc {
          max-width: 444px;
          margin-right: auto;
        }

        .contactTitle {
          @if $type =="" {
            @include fontEn;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.02em;
          }

          @if $type ==sp {
            font-size: 35px;
            text-align: center;
          }

          @if $type ==pc {
            font-size: 50px;
          }
        }

        .contactNote {
          @if $type =="" {}

          @if $type ==sp {
            margin-top: 30px;
            font-size: 14px;
            line-height: 1.56;
          }

          @if $type ==pc {
            margin-top: 30px;
            font-size: 16px;
            line-height: 1.875;
          }
        }
      }

      .contactBody {
        @if $type =="" {}

        @if $type ==sp {
          margin-top: 30px;
        }

        @if $type ==pc {
          width: 50%;
          max-width: 425px;
          margin-inline: auto;
        }

        @if $type ==tb {
          width: 100%;
          max-width: inherit;
          margin-inline: inherit;
          margin-top: 55px;
        }

        .contactNum {
          @if $type =="" {
            display: flex;
            align-items: baseline;
            justify-content: center;
            line-height: 1;
            @include fontYu;
          }

          @if $type ==sp {}

          @if $type ==pc {}

          span {
            @if $type =="" {}

            @if $type ==sp {
              font-size: 15px;
            }

            @if $type ==pc {
              font-size: 22px;
            }
          }

          strong {
            @if $type =="" {
              font-weight: 400;
            }

            @if $type ==sp {
              margin-left: 8px;
              font-size: 35px;
            }

            @if $type ==pc {
              margin-left: 8px;
              font-size: 48px;
            }
          }

          a {
            @if $type =="" {}

            @if $type ==sp {
              color: #fff;
              text-decoration: none;
            }

            @if $type ==pc {}
          }
        }

        .contactBtn {
          @if $type =="" {}

          @if $type ==sp {
            margin-top: 38px;
          }

          @if $type ==pc {
            margin-top: 45px;
          }

          a {
            @if $type =="" {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $c_base;
              font-weight: 500;
              border-radius: 9999px;
              background: #fff;
            }

            @if $type ==sp {
              padding: 18px 10px;
              font-size: 17px;
            }

            @if $type ==pc {
              padding: 24px 10px;
              font-size: 18px;
              overflow: hidden;

              &:hover,
              &:focus-visible {
                filter: inherit;

                >span {
                  span {
                    transform: translateY(1.77em);
                  }
                }
              }
            }

            &:before {
              @if $type =="" {
                content: "";
                display: block;
                background: url(../images2024/common/icon_mail.svg) no-repeat center;
                background-size: contain;
              }

              @if $type ==sp {
                width: 22px;
                height: 22px;
                margin-right: 18px;
              }

              @if $type ==pc {
                width: 28px;
                height: 28px;
                margin-right: 35px;
              }
            }

            >span {
              @if $type =="" {
                display: block;
              }

              @if $type ==sp {}

              @if $type ==pc {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                height: 1.77em;
              }

              span {
                @if $type =="" {
                  display: block;
                }

                @if $type ==sp {}

                @if $type ==pc {
                  transition: .2s transform;
                  margin-top: -1.77em;
                }

                &:before {
                  @if $type =="" {}

                  @if $type ==sp {}

                  @if $type ==pc {
                    content: attr(data-text);
                    display: block;
                  }
                }
              }
            }

          }
        }
      }
    }
  }


  #globalFooter {
    @if $type =="" {
      background: #fff;
    }

    @if $type ==sp {
      padding: 47px 15px 30px;
    }

    @if $type ==pc {
      padding-block: 30px 70px;
    }

    #pageTop {
      @if $type =="" {
        appearance: none;
        border: none;
        background: rgb(197, 33, 100);
        background: linear-gradient(180deg, rgba(197, 33, 100, 1) 26%, rgba(242, 86, 127, 1) 100%);
        border-radius: 50%;
        position: fixed;
        cursor: pointer;
      }

      @if $type ==sp {
        width: 37px;
        height: 37px;
        bottom: 20px;
        right: 20px;
      }

      @if $type ==pc {
        width: 74px;
        height: 74px;
        bottom: 40px;
        right: 40px;

        &:hover,
        &:focus-visible {
          &:before {
            top: 25px;
          }
        }
      }

      &:before {
        @if $type =="" {
          content: "";
          display: block;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;
          position: absolute;
          right: 0;
          left: 0;
          margin: auto;
          transform: rotate(-135deg);
        }

        @if $type ==sp {
          width: 8px;
          height: 8px;
          top: 16px;
        }

        @if $type ==pc {
          width: 17px;
          height: 17px;
          top: 33px;
          transition: .2s top;
        }
      }
    }

    .gfInner {
      @if $type =="" {}

      @if $type ==sp {}

      @if $type ==pc {
        @include maxPC;
      }

      .gfLogo {
        @if $type =="" {}

        @if $type ==sp {
          width: 42px;
        }

        @if $type ==pc {
          display: none !important;
        }
      }

      .gfNavList {
        @if $type =="" {}

        @if $type ==sp {
          width: 240px;
          margin-top: 27px;
          column-count: 2;
        }

        @if $type ==pc {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
        }

        >li {
          @if $type =="" {}

          @if $type ==sp {
            width: 110px;
            margin-right: 10px;

            &:not(:first-child) {
              margin-top: 11px;
            }
          }

          @if $type ==pc {
            margin-top: 35px;
            margin-inline: 35px;
          }

          a {
            @if $type =="" {}

            @if $type ==sp {
              font-size: 10px;
              font-weight: 500;
            }

            @if $type ==pc {
              font-size: 16px;
              transition: .2s opacity;

              &:hover,
              &:focus {
                opacity: .7;
              }
            }
          }
        }
      }

      .snsList {
        @if $type =="" {
          display: flex;
          align-items: center;
        }

        @if $type ==sp {
          margin-top: 39px;
        }

        @if $type ==pc {
          justify-content: center;
          margin-top: 108px;
        }

        >li {
          @if $type =="" {}

          @if $type ==sp {
            +li {
              margin-left: 20px;
            }
          }

          @if $type ==pc {
            margin-inline: 12px;
          }

          a {
            @if $type =="" {
              display: block;
            }

            @if $type ==sp {
              width: 21px;
              height: 21px;
            }

            @if $type ==pc {
              width: 34px;
              height: 34px;

              &:hover,
              &:focus-visible {
                svg {
                  fill: #f8657d;
                }
              }
            }

            svg {
              @if $type =="" {
                fill: $c_base;
              }

              @if $type ==sp {
                width: 21px;
                height: 21px;
              }

              @if $type ==pc {
                width: 34px;
                height: 34px;
                transition: .2s fill;

              }

              use {
                @if $type =="" {
                  display: block;
                }

                @if $type ==sp {
                  width: 21px;
                  height: 21px;
                }

                @if $type ==pc {
                  width: 34px;
                  height: 34px;
                }
              }
            }
          }
        }
      }

      .ghCopyright {
        @if $type =="" {}

        @if $type ==sp {
          margin-top: 36px;
        }

        @if $type ==pc {
          margin-top: 110px;
          text-align: center;
        }

        small {
          @if $type =="" {
            color: #283249;
            letter-spacing: 0.06em;
          }

          @if $type ==sp {
            font-size: 9px;
            font-weight: 500;
          }

          @if $type ==pc {
            font-size: 13px;
          }
        }
      }
    }
  }

  /* ココから下には記入禁止 */
  .forPC {
    @if $type ==sp {
      display: none !important;
    }
  }

  .forSP {
    @if $type ==pc {
      display: none !important;
    }
  }