@charset "UTF-8";

// IMPPRT INIT

@import "lib/_sanitize";    // Reset CSS
@import "_setting";
$type: "";
@import "_common";


// FOR HEADER DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:1333.9px) {
 $type: header_sp;
 @import "_common";
}

// FOR HEADER DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and ( min-width: 1334px) {

 $type: header_pc;
 @import "_common";
}

// FOR HEADER DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:1333.9px) {

	$type: header_tb;
	@import "_common";
   }

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
	@import "_common";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_common";
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
  $type: tb;
  @import "_common";
}


// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
