@charset "UTF-8";
*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  cursor: default;
  line-height: 1.5;
  -moz-tab-size: 4;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  word-break: break-word;
}

hr {
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

button,
input,
select {
  margin: 0;
}

button {
  overflow: visible;
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #a0a0a0;
  padding: 0.35em 0.75em 0.625em;
}

input {
  overflow: visible;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

select {
  text-transform: none;
}

textarea {
  margin: 0;
  overflow: auto;
  resize: vertical;
}

[type=checkbox],
[type=radio] {
  padding: 0;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

details {
  display: block;
}

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[hidden] {
  display: none;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
body {
  font-weight: 500;
  color: #222222;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  overflow-x: hidden;
  position: relative;
}

a {
  color: #000000;
  text-decoration: none;
}

.eng {
  font-family: Petrona, serif;
}

ul,
ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

* {
  box-sizing: border-box;
}

sup,
sub {
  font-size: 0.6em;
}

.dInlineBlock {
  display: inline-block !important;
}

.fontEn {
  font-family: "Baskervville", sans-serif;
}

/* ----------------- header ------------------ */
header {
  font-weight: 400;
  letter-spacing: 0;
}
header #headerInner #haderMenu li.contactCell {
  font-family: "Noto Sans JP", "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
}
main {
  display: block;
}

.btnWrap {
  text-align: center;
}
.btnWrap a {
  display: inline-block;
  color: #fff;
  border-radius: 9999px;
  text-align: center;
  background: rgb(248, 101, 125);
  background: linear-gradient(222deg, rgb(248, 101, 125) 0%, rgb(196, 31, 99) 100%);
  position: relative;
}
.btnWrap a:before {
  content: "";
  display: block;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  margin-block: auto;
}
.btnWrap a > span span {
  display: block;
}
#sectionContact {
  color: #fff;
  background: rgb(254, 113, 114);
  background: linear-gradient(100deg, rgb(254, 113, 114) 0%, rgb(196, 32, 99) 100%);
}
#sectionContact .inner .contactHeader .contactTitle {
  font-family: "Baskervville", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.02em;
}
#sectionContact .inner .contactBody .contactNum {
  display: flex;
  align-items: baseline;
  justify-content: center;
  line-height: 1;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}
#sectionContact .inner .contactBody .contactNum strong {
  font-weight: 400;
}
#sectionContact .inner .contactBody .contactBtn a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c41f63;
  font-weight: 500;
  border-radius: 9999px;
  background: #fff;
}
#sectionContact .inner .contactBody .contactBtn a:before {
  content: "";
  display: block;
  background: url(../images2024/common/icon_mail.svg) no-repeat center;
  background-size: contain;
}
#sectionContact .inner .contactBody .contactBtn a > span {
  display: block;
}
#sectionContact .inner .contactBody .contactBtn a > span span {
  display: block;
}
#globalFooter {
  background: #fff;
}
#globalFooter #pageTop {
  appearance: none;
  border: none;
  background: rgb(197, 33, 100);
  background: linear-gradient(180deg, rgb(197, 33, 100) 26%, rgb(242, 86, 127) 100%);
  border-radius: 50%;
  position: fixed;
  cursor: pointer;
}
#globalFooter #pageTop:before {
  content: "";
  display: block;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  transform: rotate(-135deg);
}
#globalFooter .gfInner .snsList {
  display: flex;
  align-items: center;
}
#globalFooter .gfInner .snsList > li a {
  display: block;
}
#globalFooter .gfInner .snsList > li a svg {
  fill: #c41f63;
}
#globalFooter .gfInner .snsList > li a svg use {
  display: block;
}
#globalFooter .gfInner .ghCopyright small {
  color: #283249;
  letter-spacing: 0.06em;
}

/* ココから下には記入禁止 */
@media screen and (max-width: 1333.9px) {
  body.isOpen #globalHeader.isView {
    transform: translateY(0) !important;
  }
  .eng {
    font-family: Petrona, serif;
  }
  /* ----------------- header ------------------ */
  header {
    font-family: "Noto Sans JP", "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
    background-color: #c41f63;
    width: 100%;
    height: 65px;
    position: relative;
  }
  header.smallPC {
    width: 100%;
    height: 65px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  header.smallPC #headerInner {
    width: 100%;
  }
  header.smallPC #headerInner #haderMenu {
    display: none;
  }
  header.smallPC #headerInner .forSmallScreen {
    display: block;
  }
  header.smallPC #headerInner #logo {
    display: block;
    width: 83px;
    height: auto;
  }
  header.smallPC #headerInner #logo img {
    width: 100%;
    height: auto;
  }
  header.smallPC #headerInner .spHeadTxt {
    left: 90px;
  }
  header.smallPC #headerInner #spMenuBtn {
    width: 83px;
    height: 83px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
  header.smallPC #headerInner #spMenuBtn img {
    width: 100%;
    height: auto;
  }
  header.smallPC #headerInner #globalMenu {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 900;
    transition: all 0.3s;
  }
  header.smallPC #headerInner #globalMenu.opened {
    left: 0%;
  }
  header.smallPC #headerInner #globalMenu #spMenuClose {
    width: 83px;
    height: 83px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
  }
  header.smallPC #headerInner #globalMenu #spMenuClose img {
    width: 100%;
    height: auto;
  }
  header.smallPC #headerInner #globalMenu ul {
    width: 210px;
    min-height: 100vh;
    background-image: url(../images/common/sp-menu-bg.png);
    background-repeat: repeat;
    position: absolute;
    top: 0;
    right: -210px;
    z-index: 5;
    list-style: none;
    transition: all 0.3s;
    transition-delay: 0.3s;
    display: block;
  }
  header.smallPC #headerInner #globalMenu ul.opened {
    right: 0px;
  }
  header.smallPC #headerInner #globalMenu ul li {
    display: block;
    height: 60px;
    padding: 0;
  }
  header.smallPC #headerInner #globalMenu ul li:after {
    display: none;
  }
  header.smallPC #headerInner #globalMenu ul li a {
    font-size: 22px;
    color: #fff;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0 0 0 30px;
    line-height: 1.5;
    position: relative;
    border-radius: 0;
  }
  header.smallPC #headerInner #globalMenu ul li a:hover {
    color: #c41f63;
  }
  header.smallPC #headerInner #globalMenu ul li a:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #c41f63;
    border-right: 2px solid #c41f63;
    transform: rotate(45deg);
    position: absolute;
    top: 10px;
    left: 8px;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr {
    width: 200px;
    height: 50px;
    border-radius: 0;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.seminar {
    border: 1px solid #fff;
    margin-left: 5px;
    margin-bottom: 15px;
    font-size: 20px;
    padding: 5px 0 0;
    text-align: center;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.seminar:before {
    display: none;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.seminar .jp {
    margin-top: -7px;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.contactBtn {
    background-color: #c41f63;
    color: #fff;
    margin-left: 5px;
    line-height: 50px;
    font-size: 14px;
    padding: 0;
    text-align: center;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.contactBtn:before {
    display: none;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.contactBtn:hover {
    opacity: 0.7;
  }
  header .spHeadTxt {
    display: block;
    color: #fff;
    font-size: 11px;
    position: absolute;
    left: 80px;
    top: 19px;
    line-height: 1.3;
    text-decoration-skip: none !important;
  }
  header #logo {
    padding: 17px 0 0 13px;
    display: inline-block;
    line-height: 1;
  }
  header #logo img {
    display: block;
    width: 57px;
    height: auto;
  }
  header #spMenuBtn {
    width: 83px;
    height: 83px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
  header #spMenuBtn img {
    width: 100%;
    height: auto;
  }
  header #globalMenu {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 900;
    transition: all 0.3s;
  }
  header #globalMenu.opened {
    left: 0%;
  }
  header #globalMenu #spMenuClose {
    width: 83px;
    height: 83px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
  }
  header #globalMenu #spMenuClose img {
    width: 100%;
    height: auto;
  }
  header #globalMenu ul {
    width: 210px;
    min-height: 100vh;
    background-image: url(../images/common/sp-menu-bg.png);
    background-repeat: repeat;
    position: absolute;
    top: 0;
    right: -210px;
    z-index: 5;
    list-style: none;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
  header #globalMenu ul.opened {
    right: 0px;
  }
  header #globalMenu ul li.endOfPC {
    margin-bottom: 10px;
  }
  header #globalMenu ul li a {
    font-size: 22px;
    color: #fff;
    text-decoration: none;
    display: block;
    width: 100%;
    padding-top: 0px;
    line-height: 1.3;
    padding-top: 12px;
    padding-left: 25px;
    position: relative;
  }
  header #globalMenu ul li a:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #c41f63;
    border-right: 2px solid #c41f63;
    transform: rotate(45deg);
    position: absolute;
    top: 20px;
    left: 8px;
  }
  header #globalMenu ul li a .jp {
    display: block;
    font-size: 9px;
    color: #ccc;
    font-family: "Noto Sans JP", "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
    margin-top: -3px;
  }
  header #globalMenu ul li a.spBnr {
    width: 200px;
    height: 50px;
  }
  header #globalMenu ul li a.spBnr.seminar {
    border: 1px solid #fff;
    margin-left: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    padding-top: 10px;
  }
  header #globalMenu ul li a.spBnr.seminar:before {
    border-color: #fff;
    top: 18px;
  }
  header #globalMenu ul li a.spBnr.contactBtn {
    background-color: #c41f63;
    color: #fff;
    margin-left: 5px;
    font-size: 13px;
    padding-top: 16px;
  }
  header #globalMenu ul li a.spBnr.contactBtn:before {
    border-color: #fff;
    top: 18px;
  }
  header #headerInner #haderMenu {
    display: none;
  }
  /* ココから下には記入禁止 */
}
@media screen and (min-width: 1334px) {
  body {
    padding-top: 109px;
  }
  .eng {
    font-family: Petrona, serif;
  }
  /* ----------------- header ------------------ */
  header {
    font-family: "Petrona", serif;
    background-color: #c41f63;
    height: 109px;
    line-height: 1.5;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  header .forSmallScreen {
    display: none;
  }
  header .spHeadTxt {
    display: none;
  }
  header #logo {
    padding: 30px 45px 0 35px;
    float: left;
    line-height: 1;
  }
  header #logo img {
    width: 84px;
    height: auto;
  }
  header #globalMenu {
    float: left;
  }
  header #globalMenu ul {
    list-style: none;
    display: table;
    height: 109px;
  }
  header #globalMenu ul li {
    display: table-cell;
    height: 109px;
    vertical-align: middle;
    position: relative;
  }
  header #globalMenu ul li.forSmallScreen {
    display: none;
  }
  header #globalMenu ul li:not(.endOfPC) {
    padding-right: 13px;
  }
  header #globalMenu ul li:not(.endOfPC):after {
    content: "";
    display: inline-block;
    margin-left: 13px;
    width: 1px;
    height: 14px;
    background-color: #fff;
    vertical-align: middle;
  }
  header #globalMenu ul li a {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    border-radius: 10px 0 10px 0;
  }
  header #globalMenu ul li a:hover {
    background-color: rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 3px #000;
    transition: all 0.3s;
  }
  header #globalMenu ul li a .jp {
    display: none;
  }
  header #headerInner:after {
    content: "";
    display: block;
    clear: both;
  }
  header #headerInner #haderMenu {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin-right: 35px;
  }
  header #headerInner #haderMenu li {
    display: table-cell;
    padding-top: 36px;
    padding-left: 10px;
  }
  header #headerInner #haderMenu li.contactCell {
    position: relative;
    width: 205px;
    background-image: url(../images/common/header-tel.png);
    background-repeat: no-repeat;
    background-position: 10px 27px;
    padding-top: 59px;
    padding-left: 10px;
  }
  header #headerInner #haderMenu li.contactCell .contactBtn {
    display: block;
    background-color: #fff;
    position: absolute;
    color: #c41f63;
    text-decoration: none;
    width: 195px;
    height: 23px;
    padding-left: 30px;
    font-size: 14px;
    line-height: 23px;
    transition: all 0.3s;
  }
  header #headerInner #haderMenu li.contactCell .contactBtn:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid #c41f63;
    border-right: 2px solid #c41f63;
    position: absolute;
    top: 8px;
    left: 10px;
    transform: rotate(45deg);
  }
  header #headerInner #haderMenu li.contactCell .contactBtn:hover {
    opacity: 0.8;
    border-radius: 5px;
  }
  header #headerInner #haderMenu li a {
    display: block;
  }
  header #headerInner #haderMenu li a.seminar {
    width: 186px;
    height: 55px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 19px;
    padding-left: 25px;
    padding-top: 3px;
    position: relative;
    text-decoration: none;
    transition: all 0.3s;
  }
  header #headerInner #haderMenu li a.seminar:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    top: 21px;
    left: 8px;
    transform: rotate(45deg);
    transition: all 0.3s;
  }
  header #headerInner #haderMenu li a.seminar span {
    font-family: "Noto Sans JP", "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
    font-size: 9px;
    display: block;
    transition: all 0.3s;
  }
  header #headerInner #haderMenu li a.seminar:hover {
    background-color: #fff;
    color: #c41f63;
  }
  header #headerInner #haderMenu li a.seminar:hover:after {
    border-color: #c41f63;
  }
  header #headerInner #haderMenu li a.seminar:hover span {
    color: #c41f63;
  }
  header #headerInner #haderMenu li a.seminar.wide {
    width: 205px;
  }
  /* ココから下には記入禁止 */
}
@media screen and (min-width: 769.09px) and (max-width: 1333.9px) {
  .eng {
    font-family: Petrona, serif;
  }
  /* ----------------- header ------------------ */
  header .spHeadTxt {
    font-size: 12px;
    left: 90px;
    top: 18px;
  }
  header #logo {
    padding-top: 15px;
    padding-left: 15px;
  }
  header #globalMenu ul li.endOfPC {
    margin-bottom: 0;
  }
  header #globalMenu ul li a .jp {
    font-size: 10px;
  }
  /* ココから下には記入禁止 */
}
@media screen and (max-width: 768.99px) {
  body {
    font-size: 15px;
    line-height: 1.55;
    -webkit-overflow-scrolling: touch;
  }
  .eng {
    font-family: Petrona, serif;
  }
  .tel {
    color: #c41f63;
    text-decoration: underline;
  }
  /* ----------------- header ------------------ */
  .section .inner {
    padding: 0 14px;
    margin: 0 auto;
  }
  * + .btnWrap {
    margin-top: 22px;
  }
  .btnWrap a {
    min-width: 230px;
    padding: 14px 30px;
    font-size: 15px;
    letter-spacing: 0.1em;
  }
  .btnWrap a:before {
    width: 8px;
    height: 8px;
    right: 20px;
  }
  #sectionContact {
    padding-block: 47px 62px;
  }
  #sectionContact .inner .contactHeader .contactTitle {
    font-size: 35px;
    text-align: center;
  }
  #sectionContact .inner .contactHeader .contactNote {
    margin-top: 30px;
    font-size: 14px;
    line-height: 1.56;
  }
  #sectionContact .inner .contactBody {
    margin-top: 30px;
  }
  #sectionContact .inner .contactBody .contactNum span {
    font-size: 15px;
  }
  #sectionContact .inner .contactBody .contactNum strong {
    margin-left: 8px;
    font-size: 35px;
  }
  #sectionContact .inner .contactBody .contactNum a {
    color: #fff;
    text-decoration: none;
  }
  #sectionContact .inner .contactBody .contactBtn {
    margin-top: 38px;
  }
  #sectionContact .inner .contactBody .contactBtn a {
    padding: 18px 10px;
    font-size: 17px;
  }
  #sectionContact .inner .contactBody .contactBtn a:before {
    width: 22px;
    height: 22px;
    margin-right: 18px;
  }
  #globalFooter {
    padding: 47px 15px 30px;
  }
  #globalFooter #pageTop {
    width: 37px;
    height: 37px;
    bottom: 20px;
    right: 20px;
  }
  #globalFooter #pageTop:before {
    width: 8px;
    height: 8px;
    top: 16px;
  }
  #globalFooter .gfInner .gfLogo {
    width: 42px;
  }
  #globalFooter .gfInner .gfNavList {
    width: 240px;
    margin-top: 27px;
    column-count: 2;
  }
  #globalFooter .gfInner .gfNavList > li {
    width: 110px;
    margin-right: 10px;
  }
  #globalFooter .gfInner .gfNavList > li:not(:first-child) {
    margin-top: 11px;
  }
  #globalFooter .gfInner .gfNavList > li a {
    font-size: 10px;
    font-weight: 500;
  }
  #globalFooter .gfInner .snsList {
    margin-top: 39px;
  }
  #globalFooter .gfInner .snsList > li + li {
    margin-left: 20px;
  }
  #globalFooter .gfInner .snsList > li a {
    width: 21px;
    height: 21px;
  }
  #globalFooter .gfInner .snsList > li a svg {
    width: 21px;
    height: 21px;
  }
  #globalFooter .gfInner .snsList > li a svg use {
    width: 21px;
    height: 21px;
  }
  #globalFooter .gfInner .ghCopyright {
    margin-top: 36px;
  }
  #globalFooter .gfInner .ghCopyright small {
    font-size: 9px;
    font-weight: 500;
  }
  /* ココから下には記入禁止 */
  .forPC {
    display: none !important;
  }
}
@media print, screen and (min-width: 769.09px) {
  body {
    font-size: 18px;
    line-height: 1.77;
  }
  .eng {
    font-family: Petrona, serif;
  }
  .tel {
    color: inherit !important;
    text-decoration: none !important;
    pointer-events: none;
  }
  /* ----------------- header ------------------ */
  .section .inner {
    max-width: 1220px;
    padding: 0 40px;
    margin: 0 auto;
  }
  * + .btnWrap {
    margin-top: 65px;
  }
  .btnWrap a {
    min-width: 260px;
    padding: 9px 30px;
    font-size: 19px;
    letter-spacing: 0.1em;
    transition: 0.2s filter;
  }
  .btnWrap a:focus-visible > span span, .btnWrap a:hover > span span {
    transform: translateY(1.67em);
  }
  .btnWrap a:before {
    width: 7px;
    height: 7px;
    right: 20px;
  }
  .btnWrap a > span {
    display: flex;
    flex-direction: column;
    height: 1.67em;
    overflow: hidden;
  }
  .btnWrap a > span span {
    margin-top: -1.67em;
    transition: 0.2s transform;
  }
  .btnWrap a > span span:before {
    content: attr(data-text);
    display: block;
  }
  #sectionContact {
    padding-block: 115px 114px;
  }
  #sectionContact .inner {
    display: flex;
    align-items: center;
    max-width: 1220px;
    padding: 0 40px;
    margin: 0 auto;
  }
  #sectionContact .inner .contactHeader {
    max-width: 444px;
    margin-right: auto;
  }
  #sectionContact .inner .contactHeader .contactTitle {
    font-size: 50px;
  }
  #sectionContact .inner .contactHeader .contactNote {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.875;
  }
  #sectionContact .inner .contactBody {
    width: 50%;
    max-width: 425px;
    margin-inline: auto;
  }
  #sectionContact .inner .contactBody .contactNum span {
    font-size: 22px;
  }
  #sectionContact .inner .contactBody .contactNum strong {
    margin-left: 8px;
    font-size: 48px;
  }
  #sectionContact .inner .contactBody .contactBtn {
    margin-top: 45px;
  }
  #sectionContact .inner .contactBody .contactBtn a {
    padding: 24px 10px;
    font-size: 18px;
    overflow: hidden;
  }
  #sectionContact .inner .contactBody .contactBtn a:hover, #sectionContact .inner .contactBody .contactBtn a:focus-visible {
    filter: inherit;
  }
  #sectionContact .inner .contactBody .contactBtn a:hover > span span, #sectionContact .inner .contactBody .contactBtn a:focus-visible > span span {
    transform: translateY(1.77em);
  }
  #sectionContact .inner .contactBody .contactBtn a:before {
    width: 28px;
    height: 28px;
    margin-right: 35px;
  }
  #sectionContact .inner .contactBody .contactBtn a > span {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 1.77em;
  }
  #sectionContact .inner .contactBody .contactBtn a > span span {
    transition: 0.2s transform;
    margin-top: -1.77em;
  }
  #sectionContact .inner .contactBody .contactBtn a > span span:before {
    content: attr(data-text);
    display: block;
  }
  #globalFooter {
    padding-block: 30px 70px;
  }
  #globalFooter #pageTop {
    width: 74px;
    height: 74px;
    bottom: 40px;
    right: 40px;
  }
  #globalFooter #pageTop:hover:before, #globalFooter #pageTop:focus-visible:before {
    top: 25px;
  }
  #globalFooter #pageTop:before {
    width: 17px;
    height: 17px;
    top: 33px;
    transition: 0.2s top;
  }
  #globalFooter .gfInner {
    max-width: 1220px;
    padding: 0 40px;
    margin: 0 auto;
  }
  #globalFooter .gfInner .gfLogo {
    display: none !important;
  }
  #globalFooter .gfInner .gfNavList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  #globalFooter .gfInner .gfNavList > li {
    margin-top: 35px;
    margin-inline: 35px;
  }
  #globalFooter .gfInner .gfNavList > li a {
    font-size: 16px;
    transition: 0.2s opacity;
  }
  #globalFooter .gfInner .gfNavList > li a:hover, #globalFooter .gfInner .gfNavList > li a:focus {
    opacity: 0.7;
  }
  #globalFooter .gfInner .snsList {
    justify-content: center;
    margin-top: 108px;
  }
  #globalFooter .gfInner .snsList > li {
    margin-inline: 12px;
  }
  #globalFooter .gfInner .snsList > li a {
    width: 34px;
    height: 34px;
  }
  #globalFooter .gfInner .snsList > li a:hover svg, #globalFooter .gfInner .snsList > li a:focus-visible svg {
    fill: #f8657d;
  }
  #globalFooter .gfInner .snsList > li a svg {
    width: 34px;
    height: 34px;
    transition: 0.2s fill;
  }
  #globalFooter .gfInner .snsList > li a svg use {
    width: 34px;
    height: 34px;
  }
  #globalFooter .gfInner .ghCopyright {
    margin-top: 110px;
    text-align: center;
  }
  #globalFooter .gfInner .ghCopyright small {
    font-size: 13px;
  }
  /* ココから下には記入禁止 */
  .forSP {
    display: none !important;
  }
}
@media screen and (min-width: 769.09px) and (max-width: 1100.99px) {
  .eng {
    font-family: Petrona, serif;
  }
  /* ----------------- header ------------------ */
  header.smallPC #headerInner {
    width: 100%;
  }
  header.smallPC #headerInner #haderMenu {
    display: none;
  }
  header.smallPC #headerInner .forSmallScreen {
    display: block;
  }
  header.smallPC #headerInner #logo {
    display: block;
    width: 83px;
    height: auto;
  }
  header.smallPC #headerInner #logo img {
    width: 100%;
    height: auto;
  }
  header.smallPC #headerInner #spMenuBtn {
    width: 83px;
    height: 83px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
  header.smallPC #headerInner #spMenuBtn img {
    width: 100%;
    height: auto;
  }
  header.smallPC #headerInner #globalMenu {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 900;
    transition: all 0.3s;
  }
  header.smallPC #headerInner #globalMenu.opened {
    left: 0%;
  }
  header.smallPC #headerInner #globalMenu #spMenuClose {
    width: 83px;
    height: 83px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
  }
  header.smallPC #headerInner #globalMenu #spMenuClose img {
    width: 100%;
    height: auto;
  }
  header.smallPC #headerInner #globalMenu ul {
    width: 210px;
    min-height: 100vh;
    background-image: url(../images/common/sp-menu-bg.png);
    background-repeat: repeat;
    position: absolute;
    top: 0;
    right: -210px;
    z-index: 5;
    list-style: none;
    transition: all 0.3s;
    transition-delay: 0.3s;
    display: block;
  }
  header.smallPC #headerInner #globalMenu ul.opened {
    right: 0px;
  }
  header.smallPC #headerInner #globalMenu ul li {
    display: block;
    height: 60px;
    padding: 0;
  }
  header.smallPC #headerInner #globalMenu ul li:after {
    display: none;
  }
  header.smallPC #headerInner #globalMenu ul li a {
    font-size: 22px;
    color: #fff;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0 0 0 30px;
    position: relative;
    border-radius: 0;
  }
  header.smallPC #headerInner #globalMenu ul li a:hover {
    color: #c41f63;
  }
  header.smallPC #headerInner #globalMenu ul li a:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #c41f63;
    border-right: 2px solid #c41f63;
    transform: rotate(45deg);
    position: absolute;
    top: 10px;
    left: 8px;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr {
    width: 200px;
    height: 50px;
    border-radius: 0;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.seminar {
    border: 1px solid #fff;
    margin-left: 5px;
    margin-bottom: 15px;
    font-size: 20px;
    padding: 5px 0 0;
    text-align: center;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.seminar:before {
    display: none;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.seminar .jp {
    margin-top: -7px;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.contactBtn {
    background-color: #c41f63;
    color: #fff;
    margin-left: 5px;
    line-height: 50px;
    font-size: 14px;
    padding: 0;
    text-align: center;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.contactBtn:before {
    display: none;
  }
  header.smallPC #headerInner #globalMenu ul li a.spBnr.contactBtn:hover {
    opacity: 0.7;
  }
  #sectionContact .inner {
    flex-direction: column;
  }
  #sectionContact .inner .contactBody {
    width: 100%;
    max-width: inherit;
    margin-inline: inherit;
    margin-top: 55px;
  }
  /* ココから下には記入禁止 */
}